import { isEmpty } from 'lodash';

export const clearQueryParams = () => {
  window.history.replaceState(null, null, window.location.pathname);
};

export const decodeURLFromParams = (paramQuery: string): string | null => {
  const search = window.location.search;
  if (!search) return null;

  const params = new URLSearchParams(search);
  const encodedParams = params.get(paramQuery);
  if (isEmpty(encodedParams)) return null;
  const decodedParams = decodeURIComponent(encodedParams);
  return decodedParams;
};

export const logoUrl = 'https://static.timetemp.io/assets/images/blue-logo.png'
 