import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  getCompaniesFromLocalStorage,
  getUserIdTokenFromLocalStorage,
  clearStorage,
} from 'utils/storages';
import { redirectToVcIdentity } from 'utils/sessions';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const companies = getCompaniesFromLocalStorage();
  const idToken = getUserIdTokenFromLocalStorage();

  const expiredAt = isEmpty(companies) ? null : companies[0].expired_at;
  const isExpired = expiredAt ? new Date() >= new Date(expiredAt) : true;
  const isAuthenticated = !isExpired || !isEmpty(idToken);

  if (isAuthenticated) return <Route {...props} />;

  clearStorage();
  const search = props.location?.search || '';
  redirectToVcIdentity(search);
  return null;
};
export default PrivateRoute;
