import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import sagaActions from 'constants/sagaActions';
import sliderNames from 'constants/sliderNames';
import {
  GetRegionIdsPayLoad,
  GetIdTokenParams,
} from 'types/sessionsTypes';
import {
  setRegionIdsToLocalStorage,
} from 'utils/storages';

type SessionsState = {
  regionIds: string[];
};

export const initialState: SessionsState = {
  regionIds: [],
};

const handleGetRegionIdsRequest = () => {};

const handleGetRegionIdsSuccess = (
  state: SessionsState,
  action: PayloadAction<GetRegionIdsPayLoad>
) => {
  const { regionIds } = action.payload;
  state.regionIds = regionIds;
  setRegionIdsToLocalStorage(regionIds);
};

const sessionsSlice = createSlice({
  name: sliderNames.SESSIONS,
  initialState,
  reducers: {
    getRegionIdsRequest: handleGetRegionIdsRequest,
    getRegionIdsSuccess: handleGetRegionIdsSuccess,
  },
});

export const { getRegionIdsRequest, getRegionIdsSuccess } =
  sessionsSlice.actions;

export default sessionsSlice.reducer;

export const logOut = () => ({
  type: sagaActions.LOGOUT,
});

export const getIdToken = (payload: GetIdTokenParams) => ({
  type: sagaActions.GET_ID_TOKEN,
  payload,
});
