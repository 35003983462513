import { takeLatest, put, call } from 'redux-saga/effects';
import { getCompaniesFromAllRegions } from 'apis/companies';
import sagaActions from 'constants/sagaActions';
import {
  getCompaniesFailed,
  getCompaniesSuccess,
  requestGettingCompanies,
  startGettingCompanies,
  stopGettingCompanies,
} from 'slices/companiesSlice';
import {
  getRegionIdsFromLocalStorage,
  clearSessionFromStorageAndCookies,
} from 'utils/storages';
import { redirectToVcIdentity } from 'utils/sessions';
import { constructCompanyList } from 'utils/companies';

export function* getAllCompanies() {
  try {
    yield put(startGettingCompanies());
    yield put(requestGettingCompanies());
    const regionIds = getRegionIdsFromLocalStorage();
    const resp = yield call(getCompaniesFromAllRegions, regionIds);
    if (!resp) {
      throw new Error('No response from get companies api');
    }
    const companies = [];
    const errors = [];
    resp.forEach((respValue) => {
      if (!respValue) return;
      if (respValue instanceof Error) {
        errors.push(respValue);
      } else {
        companies.push(respValue);
      }
    });
    if (companies.length > 0) {
      const constructedCompanies = constructCompanyList(companies);
      yield put(getCompaniesSuccess({ companies: constructedCompanies }));
    } else if (
      errors.some((err) => err.isAxiosError && err.response.status == 401)
    ) {
      yield put(getCompaniesFailed());
      yield clearSessionFromStorageAndCookies();
      redirectToVcIdentity();
    }
  } catch (error) {
    yield put(getCompaniesFailed());
  } finally {
    yield put(stopGettingCompanies());
  }
}

export default [takeLatest(sagaActions.GET_USER_COMPANIES, getAllCompanies)];
