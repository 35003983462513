import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import sliderNames from 'constants/sliderNames';
import { RedirectPayload } from 'types/redirectTypes';

type RedirectState = {
  redirectUrl: string;
  shouldReplaceUrl: boolean;
};

export const initialState: RedirectState = {
  redirectUrl: '',
  shouldReplaceUrl: false,
};

const handleSetRedirectUrl = (
  state: RedirectState,
  action: PayloadAction<RedirectPayload>
) => {
  const { url, shouldReplaceUrl = false } = action.payload;
  state.redirectUrl = url;
  state.shouldReplaceUrl = shouldReplaceUrl;
};

const handleResetRedirectUrl = (state: RedirectState) => {
  state.redirectUrl = '';
  state.shouldReplaceUrl = false;
};

const redirectSlice = createSlice({
  name: sliderNames.REDIRECT,
  initialState,
  reducers: {
    setRedirectUrl: handleSetRedirectUrl,
    resetRedirectUrl: handleResetRedirectUrl,
  },
});

export const { setRedirectUrl, resetRedirectUrl } = redirectSlice.actions;

export default redirectSlice.reducer;
