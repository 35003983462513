import { CompaniesType, CompanyType } from 'types/companiesType';

const USER_TOKEN_ID = 'id_token';
const TT_REGION_IDS = 'tt-region-ids';
const TT_REGION_SEPARATOR = ',';
const TT_COMPANIES = 'tt-companies';
const TT_APP_TOKEN = 'tt-token';
const EMAIL = 'email';
const CODE_VERIFIER = 'code-verifier';

export const setUserIdTokenToLocalStorage = (idToken: string) => {
  localStorage.setItem(USER_TOKEN_ID, idToken);
};

export const getUserIdTokenFromLocalStorage = () => {
  return localStorage.getItem(USER_TOKEN_ID);
};

export const setRegionIdsToLocalStorage = (regionIds: string[]) => {
  localStorage.setItem(TT_REGION_IDS, regionIds.join(TT_REGION_SEPARATOR));
};

export const getRegionIdsFromLocalStorage = (): string[] => {
  const regionIds = localStorage.getItem(TT_REGION_IDS);
  return regionIds.split(TT_REGION_SEPARATOR);
};

export const removeRegionIdsFromLocalStorage = () => {
  localStorage.removeItem(TT_REGION_IDS);
};

export const setCompaniesToLocalStorage = (companies: CompaniesType) => {
  localStorage.setItem(TT_COMPANIES, JSON.stringify(companies));
};

export const getCompaniesFromLocalStorage = (): CompaniesType => {
  try {
    return JSON.parse(localStorage.getItem(TT_COMPANIES));
  } catch {
    return null;
  }
};

export const setAppTokenToLocalStorage = (company: CompanyType) => {
  localStorage.setItem(TT_APP_TOKEN, JSON.stringify(company));
};

export const getAppTokenFromLocalStorage = (): CompanyType => {
  try {
    return JSON.parse(localStorage.getItem(TT_APP_TOKEN));
  } catch {
    return null;
  }
};

export const setEmailToLocalStorage = (email: string) => {
  localStorage.setItem(EMAIL, email);
};

export const getEmailFromLocalStorage = () => localStorage.getItem(EMAIL);

export const removeEmailFromLocalStorage = () => localStorage.removeItem(EMAIL);

export const getCodeVerifierFromLocalStorage = (): string | null => {
  return localStorage.getItem(CODE_VERIFIER);
};

export const setCodeVerifierToLocalStorage = (verifyCode: string) => {
  return localStorage.setItem(CODE_VERIFIER, verifyCode);
};

export const removeCodeVerifierFromLocalStorage = () =>
  localStorage.removeItem(CODE_VERIFIER);

export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const clearSessionFromStorageAndCookies = () => {
  clearStorage();
};
