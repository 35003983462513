import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';
import LoadableWrapper from 'components/LoadableWrapper';
import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
import routes from 'constants/routes';

const asLoadable = (loader) =>
  loadable(loader, {
    fallback: <LoadableWrapper />,
  });

const CompaniesSelectPageView = asLoadable(
  async () => import('pages/CompaniesSelectPage')
);
const LogoutPageView = asLoadable(async () => import('pages/LogoutPage'));

const AuthorizationCallbackPageView = asLoadable(
  async () => import('pages/AuthorizationCallbackPage')
);

const CompanySwitchPageView = asLoadable(
  async () => import('pages/CompanySwitchPage')
);

const NotFoundView = asLoadable(async () => import('pages/NotFoundPage'));

const App = () => {
  return (
    <Switch>
      <PublicRoute
        exact
        component={AuthorizationCallbackPageView}
        path={routes.AUTHORIZATION_CALLBACK.path}
      />
      <PublicRoute
        exact
        component={(props) => {
          props.history.replace('/');
          return null;
        }}
        path={routes.LOGIN.path}
      />
      <PrivateRoute
        exact
        component={LogoutPageView}
        path={routes.LOGOUT.path}
      />
      <PrivateRoute
        exact
        component={CompaniesSelectPageView}
        path={routes.COMPANIES_SELECT.path}
      />
      <PrivateRoute
        exact
        component={CompanySwitchPageView}
        path={`${routes.COMPANY_SWITCHING.path}/:companyIndex`}
      />
      <Route component={NotFoundView} />
    </Switch>
  );
};

export default App;
