import Loader from 'components/Loader';
import css from './LoadableWrapper.module.scss';

const LoadableWrapper = () => {
  return (
    <div className={css['container']}>
      <Loader size="lg" />
    </div>
  );
};

export default LoadableWrapper;
