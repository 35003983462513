import axios from 'axios';
import { get, isEmpty } from 'lodash';
import { HTTP_RESPONSE_STATUSES } from 'constants/httpStatusCode';

const DEFAULT_TIMEOUT = 10000;

const { SERVER_ERROR } = HTTP_RESPONSE_STATUSES;

export const oidcErrorHandler = async (error) => {
  if (error) {
    const response = error.response;
    const { status } = response;

    if (status >= SERVER_ERROR) {
      console.log('Internal server error');
    }
    return Promise.reject(response);
  }
  return Promise.resolve();
};

export const responseWithVcSession = (response) => {
  const vcSessionId = get(response, 'headers.vcsessionid', '');
  if (!isEmpty(vcSessionId)) {
    return {
      ...response.data,
      vcSessionId,
    };
  }
  return response.data;
};

const oidc_api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_OIDC_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'app-id': process.env.REACT_APP_HEADER_APP_ID,
  },
});

oidc_api.interceptors.response.use(responseWithVcSession, oidcErrorHandler);

const tt_api = axios.create({
  timeout: DEFAULT_TIMEOUT,
});

export const ttErrorHandler = async (error) => {
  if (error) {
    const { status } = error.response;

    if (status >= SERVER_ERROR) {
      console.log('Internal server error');
      // TT-Error will be resolve as not data return
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const InterceptorService = {
  setupTtApiInterceptor: () => {
    tt_api.interceptors.response.use(
      (response) => response.data,
      async (error) => ttErrorHandler(error)
    );
  },
};

export { oidc_api, tt_api };
