import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { CompanyType } from 'types/companiesType';
import {
  getCompaniesFromLocalStorage,
  getCodeVerifierFromLocalStorage,
  setCodeVerifierToLocalStorage,
  getUserIdTokenFromLocalStorage,
} from './storages';
import routes from 'constants/routes';

export const getHostByEnv = (host: string): string => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (!isDevelopment) return host;

  let redirectHost = host;

  switch (host) {
    case process.env.REACT_APP_TT_APP_DEV:
      redirectHost = process.env.REACT_APP_LOCAL_TT_APP_DEV;
      break;
    case process.env.REACT_APP_TT_APP_DEV1:
      redirectHost = process.env.REACT_APP_LOCAL_TT_APP_DEV1;
      break;
    case process.env.REACT_APP_TT_APP_DEV2:
      redirectHost = process.env.REACT_APP_LOCAL_TT_APP_DEV2;
      break;
    case process.env.REACT_APP_TT_APP_DEV3:
      redirectHost = process.env.REACT_APP_LOCAL_TT_APP_DEV3;
      break;
    case process.env.REACT_APP_TT_APP_DEV6:
      redirectHost = process.env.REACT_APP_LOCAL_TT_APP_DEV6;
      break;
    case process.env.REACT_APP_TT_APP_DEV7:
      redirectHost = process.env.REACT_APP_LOCAL_TT_APP_DEV7;
      break;
    case process.env.REACT_APP_TT_APP_DEV8:
      redirectHost = process.env.REACT_APP_LOCAL_TT_APP_DEV8;
      break;
    case process.env.REACT_APP_TT_APP_TEST:
      redirectHost = process.env.REACT_APP_LOCAL_TT_APP_TEST;
      break;
    default:
      redirectHost = host;
      break;
  }
  return redirectHost || host;
};

export const getSwitchCompanyByIndex = (
  index: string
): CompanyType | undefined => {
  const indexNumber = Number(index);
  if (isNaN(indexNumber)) return;

  const companies = getCompaniesFromLocalStorage();
  const switchCompany = companies[indexNumber];
  return switchCompany;
};

export const generateCodeChallenge = (codeVerifier: string): string =>
  Base64.stringify(sha256(codeVerifier))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

export const redirectToVcIdentity = (search = '') => {
  let codeVerifier = getCodeVerifierFromLocalStorage();
  if (!codeVerifier) {
    codeVerifier = (Math.random() + 1).toString(36).substring(2);
    setCodeVerifierToLocalStorage(codeVerifier);
  }
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const continueUrl = encodeURIComponent(
    window.location.origin +
      routes.AUTHORIZATION_CALLBACK.path +
      decodeURIComponent(search)
  );
  const vcIdentityUrl =
    process.env.REACT_APP_VC_IDENTITY_URL +
    `/?code_challenge_method=S256&code_challenge=${codeChallenge}&continue=${continueUrl}`;
  window.location.replace(vcIdentityUrl);
};

export const redirectToVcIdentityError = (params) => {
  const contineuUrl = window.location.origin;
  const paramsString = Object.entries({ ...params, continue: contineuUrl })
    .map((e) => e.map((v: string) => encodeURIComponent(v)).join('='))
    .join('&');
  const vcIdentityUrl =
    process.env.REACT_APP_VC_IDENTITY_URL + `/error?${paramsString}`;
  window.location.replace(vcIdentityUrl);
};

export const redirectToVcLogout = () => {
  const contineuUrl = encodeURIComponent(window.location.origin);
  const vcLogoutUrl =
    process.env.REACT_APP_VC_IDENTITY_URL + `/logout?continue=${contineuUrl}`;
  window.location.replace(vcLogoutUrl);
};

export const parseIdToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const isMfaEnabled = () => {
  const idToken = getUserIdTokenFromLocalStorage();
  if (!idToken) return false;

  const info = parseIdToken(idToken);
  let amr = info['custom:amr'];
  if (!amr) return false;

  amr = JSON.parse(amr);
  return amr.includes('otp') || amr.includes('sms');
};
