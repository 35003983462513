import cx from 'classnames';
import css from './Loader.module.scss';

type Props = {
  loaderClassName?: string;
  loaderDotClassName?: string;
  size?: LoaderSize;
};

const Loader: React.FC<Props> = ({
  loaderClassName,
  loaderDotClassName,
  size = 'sm',
}) => {
  const loaderClassNames = cx(
    css['loader'],
    css[`loader--${size}`],
    loaderClassName
  );
  const loaderDotClassNames = cx(
    css['loader--dot'],
    css[`loader--dot--${size}`],
    loaderDotClassName
  );

  return (
    <div className={loaderClassNames}>
      <div className={loaderDotClassNames} />
      <div className={loaderDotClassNames} />
      <div className={loaderDotClassNames} />
      <div className={loaderDotClassNames} />
      <div className={loaderDotClassNames} />
      <div className={loaderDotClassNames} />
    </div>
  );
};

export default Loader;
