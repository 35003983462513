import { combineReducers } from '@reduxjs/toolkit';
import companiesSlice from 'slices/companiesSlice';
import redirectSlice from 'slices/redirectSlice';
import sessionsSlice from 'slices/sessionsSlice';
import actionsNames from 'constants/sagaActions';

const appReducer = combineReducers({
  sessions: sessionsSlice,
  redirect: redirectSlice,
  companies: companiesSlice,
});

type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppState, action) => {
  if (action.type === actionsNames.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
