import { call, put, takeLatest, fork, delay } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import {
  requestRegionIds,
  requestGetIdTokenV2,
  logout as logoutApi,
} from 'apis/login';
import { getRegionIdsRequest, getRegionIdsSuccess } from 'slices/sessionsSlice';
import { setRedirectUrl } from 'slices/redirectSlice';
import sagaActions from 'constants/sagaActions';
import routes from 'constants/routes';
import {
  GetRegionIdsResponse,
  GetIdTokenV2Response,
  GetIdTokenParams,
} from 'types/sessionsTypes';
import {
  redirectToVcIdentity,
  redirectToVcIdentityError,
  redirectToVcLogout,
  isMfaEnabled,
  parseIdToken,
} from 'utils/sessions';
import {
  clearSessionFromStorageAndCookies,
  setUserIdTokenToLocalStorage,
  setEmailToLocalStorage,
} from 'utils/storages';

type GetIdTokenSagaAction = SagaAction<GetIdTokenParams>;

export function* getIdToken({ payload }: GetIdTokenSagaAction) {
  try {
    const { id_token: idToken }: GetIdTokenV2Response = yield call(
      requestGetIdTokenV2,
      payload
    );
    if (isEmpty(idToken)) {
      throw new Error('Invalid Id token');
    }
    setUserIdTokenToLocalStorage(idToken);
    const tokenInfo = parseIdToken(idToken);
    setEmailToLocalStorage(tokenInfo['email']);
    yield call(getRegionIds);
    const queryParam = window?.location?.search || '';
    yield put(
      setRedirectUrl({
        url: routes.COMPANIES_SELECT.path + queryParam,
        shouldReplaceUrl: true,
      })
    );
  } catch (error) {
    if (error && error.status === 400 && error.data.errorCode) {
      const params = {
        email: error.data.error,
        error_code: error.data.errorCode,
      };
      redirectToVcIdentityError(params);
    } else {
      redirectToVcIdentity();
    }
  }
}

export function* getRegionIds() {
  yield put(getRegionIdsRequest());
  const regionIdsList: GetRegionIdsResponse = yield call(requestRegionIds);
  const regionIds = regionIdsList.map((item) => item.regionId);
  yield put(getRegionIdsSuccess({ regionIds }));
}

export function* logOut() {
  try {
    yield fork(logoutApi);
    yield delay(300)
    const mfaEnabled = isMfaEnabled();
    yield clearSessionFromStorageAndCookies();
    if (mfaEnabled) {
      redirectToVcLogout();
    } else {
      redirectToVcIdentity();
    }
  } catch (error) {
    redirectToVcIdentity();
  }
}

export default [
  takeLatest(sagaActions.GET_ID_TOKEN, getIdToken),
  takeLatest(sagaActions.LOGOUT, logOut),
];
