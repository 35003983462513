import { oidc_api, tt_api } from 'apis';
import {
  GetRegionIdsResponse,
  GetIdTokenV2Response,
  GetIdTokenParams,
} from 'types/sessionsTypes';
import {
  getUserIdTokenFromLocalStorage,
  getCompaniesFromLocalStorage,
} from 'utils/storages';
import { isEmpty } from 'lodash';

export const requestRegionIds = async (): Promise<GetRegionIdsResponse> => {
  const idToken = getUserIdTokenFromLocalStorage();
  return oidc_api.request({
    method: 'GET',
    url: '/oauth2/user/timetemp',
    headers: {
      'id-token': idToken,
    },
  });
};

export const requestGetIdTokenV2 = async ({
  authorizationCode,
  codeVerifier,
  state,
}: GetIdTokenParams): Promise<GetIdTokenV2Response> => {
  return oidc_api.request({
    method: 'POST',
    url: '/oauth2/v2/token',
    data: {
      authorization_code: authorizationCode,
      code_verifier: codeVerifier,
      state: state,
    },
  });
};

export const logout = async () => {
  const companies = getCompaniesFromLocalStorage();
  if (isEmpty(companies)) return;

  companies.forEach((company) => {
    if (isEmpty(company.api_url)) return;

    tt_api.request({
      baseURL: company.api_url,
      url: '/v1/sessions/logout',
      method: 'POST',
      headers: {
        tenant_name: company.tenant,
        Authorization: `Bearer ${company.token}`,
      },
    });
  });
};
