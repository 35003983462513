import { CALLBACK_PATH, CALLBACK_URL } from 'constants/queryParams';
import { find, isEmpty, compact, flatten, orderBy } from 'lodash';
import { CompaniesType, UserInfoType, CompanyType } from 'types/companiesType';
import { getHostByEnv } from './sessions';
import { setAppTokenToLocalStorage } from './storages';
import { decodeURLFromParams } from './url';
import { isCorrectUrlFormat } from './validate';

const TENANT_ID_PREFIX = 'ten-';
const TENANT_ID_REGEX_CHECK = new RegExp(
  `\\/` + TENANT_ID_PREFIX + `([\\d\\w]+)\\/?`
);
const HOST_WITH_APP_ID_REGEX = new RegExp(
  /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?\/[\w\d]+\/)/
);

export const getUserInfoFromCompanyList = (
  companies: CompaniesType
): UserInfoType | null => {
  if (isEmpty(companies)) return null;

  const companyWithUser = find(
    companies,
    (company: CompanyType) => !isEmpty(company.user)
  );
  if (isEmpty(companyWithUser)) return null;

  return companyWithUser.user;
};

export const constructCompanyList = (
  companiesFromAllRegions: CompaniesType[]
) => {
  const companyList = compact(flatten(companiesFromAllRegions));
  const orderedCompanyList = orderBy(companyList, ['id', 'host']);
  return orderedCompanyList.sort((a, b) => {
    const aArchivedNumber = a.is_user_archived ? 1 : 0;
    const bArchivedNumber = b.is_user_archived ? 1 : 0;
    return aArchivedNumber - bArchivedNumber;
  });
};

export const getTenantIdPath = (tenantId) => `/${TENANT_ID_PREFIX}${tenantId}`;

export const saveSelectedCompanyAndGetRedirectHost = (
  company: CompanyType
): string => {
  const { host, id } = company;
  setAppTokenToLocalStorage(company);
  const redirectHost = getHostByEnv(host);
  const tenantIdPath = getTenantIdPath(id);
  return redirectHost + tenantIdPath;
};

export const validateAndProceedCallbackURL = (host: string) => {
  const decodedURL = decodeURLFromParams(CALLBACK_URL);
  if (isEmpty(decodedURL) || !isCorrectUrlFormat(decodedURL)) return host;

  const tenantId = extractTenantIdFromCallbackURL();
  const hasTenantIdInCallbackURL = !isEmpty(tenantId);

  if (hasTenantIdInCallbackURL && decodedURL.includes(host)) {
    return decodedURL;
  }

  if (!hasTenantIdInCallbackURL) {
    const decodedURLHostMatch = decodedURL.match(HOST_WITH_APP_ID_REGEX);
    const decodedURLHost = decodedURLHostMatch ? decodedURLHostMatch[0] : null;
    const pathname = decodedURL.replace(HOST_WITH_APP_ID_REGEX, '');

    if (decodedURLHost && host.includes(decodedURLHost)) {
      return `${host}/${pathname}`;
    }
  }

  return host;
};

export const appendCallbackPathnameToHost = (host: string): string => {
  const decodedPath = decodeURLFromParams(CALLBACK_PATH);
  if (isEmpty(decodedPath)) return host;
  return host + decodedPath;
};

export const getTenantIdInUrl = (url: string): string | null => {
  const matches = url.match(TENANT_ID_REGEX_CHECK);
  return !isEmpty(matches) ? matches[1] : null;
};

export const extractTenantIdFromCallbackURL = (): string | null => {
  const decodedURL = decodeURLFromParams(CALLBACK_URL);
  if (!decodedURL) return null;
  const tenantId = getTenantIdInUrl(decodedURL);
  return tenantId;
};

export const findCompanyInDecodedUrl = (
  decodedCallbackURL: string,
  companies: CompaniesType,
  tenantId: string | number
) => {
  return companies.find((company) => {
    const {
      id: companyId,
      host: companyHost,
      is_user_archived: isUserArchived,
    } = company;
    const redirectHost = getHostByEnv(companyHost);
    return (
      String(tenantId) === String(companyId) &&
      decodedCallbackURL.includes(redirectHost) &&
      !isUserArchived
    );
  });
};
