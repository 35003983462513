import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isEmpty } from 'lodash';
import routes from 'constants/routes';
import { getUserIdTokenFromLocalStorage } from 'utils/storages';

const PublicRoute: React.FC<RouteProps> = (props) => {
  const idToken = getUserIdTokenFromLocalStorage();

  const isAuthenticated = !isEmpty(idToken);

  return isAuthenticated ? (
    <Redirect to={routes.COMPANIES_SELECT.path} />
  ) : (
    <Route {...props} />
  );
};
export default PublicRoute;
