import { isEmpty } from 'lodash';
import { tt_api } from 'apis';
import { TT_APIS } from 'constants/apiUrls';
import { getUserIdTokenFromLocalStorage } from 'utils/storages';
import { CompaniesType, CompanyType } from 'types/companiesType';

export const getCompaniesFromRegion = async (
  regionId: string
): Promise<CompaniesType> => {
  const regionBaseUrl: string | undefined = TT_APIS[regionId];
  if (isEmpty(regionBaseUrl)) return undefined;

  const idToken = getUserIdTokenFromLocalStorage();
  return tt_api.request({
    baseURL: regionBaseUrl,
    url: 'v2/sessions/region_info',
    method: 'GET',
    headers: {
      'id-token': idToken,
    },
    params: {
      device_type: 'WEB',
    },
  });
};

export const getCompaniesFromAllRegions = async (
  regionIds: string[]
): Promise<CompaniesType[]> => {
  return Promise.all(
    regionIds
      .map(async (regionId) => getCompaniesFromRegion(regionId))
      .map(async (p) => p.catch((resp) => resp))
  );
};

export const logMultiSchema = async (
  company: CompanyType
): Promise<CompaniesType> => {
  return tt_api
    .request({
      baseURL: company.api_url,
      url: '/v2/sessions/log_multi_schema',
      method: 'POST',
      headers: {
        tenant_name: company.tenant,
        Authorization: `Bearer ${company.token}`,
      },
    })
};
