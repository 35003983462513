const routes = {
  AUTHORIZATION_CALLBACK: { path: '/authorization_callback', name: 'authorization_callback' },
  LOGOUT: { path: '/logout', name: 'logout' },
  LOGIN: { path: '/login', name: 'login' },
  COMPANIES_SELECT: { path: '/', name: 'companies' },
  COMPANY_SWITCHING: {
    path: '/authorizing_company',
    name: 'authorizing_company',
  },
};

export default routes;
