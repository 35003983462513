import { isEmpty } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import sagaActions from 'constants/sagaActions';
import sliderNames from 'constants/sliderNames';
import {
  CompaniesType,
  CompaniesListPayload,
  UserInfoType,
} from 'types/companiesType';
import { setCompaniesToLocalStorage } from 'utils/storages';
import { getUserInfoFromCompanyList } from 'utils/companies';

type CompaniesState = {
  list: CompaniesType;
  isGettingCompanies: boolean;
  isError: boolean;
  user?: UserInfoType;
};

export const initialState: CompaniesState = {
  list: [],
  isGettingCompanies: false,
  isError: false,
};

const handleStartGettingCompanies = (state: CompaniesState) => {
  state.isGettingCompanies = true;
};

const handleStopGettingCompanies = (state: CompaniesState) => {
  state.isGettingCompanies = false;
};

const handelSetCompanies = (
  state: CompaniesState,
  action: PayloadAction<CompaniesListPayload>
) => {
  const { companies } = action.payload;
  const user = getUserInfoFromCompanyList(companies);
  state.list = companies;
  state.user = user;
};

const handleGetCompaniesFailed = (state: CompaniesState) => {
  state.isError = true;
};

const handleRequestGetCompanies = (state: CompaniesState) => {
  state.isError = false;
};

const handleGetCompaniesSuccess = (
  state: CompaniesState,
  action: PayloadAction<CompaniesListPayload>
) => {
  const { companies } = action.payload;
  const hasCompanies = !isEmpty(companies);
  state.list = companies;
  if (!hasCompanies) return;

  setCompaniesToLocalStorage(companies);
  const user = getUserInfoFromCompanyList(companies);
  state.user = user;
};

const companiesSlice = createSlice({
  name: sliderNames.COMPANIES,
  initialState,
  reducers: {
    startGettingCompanies: handleStartGettingCompanies,
    stopGettingCompanies: handleStopGettingCompanies,
    setCompanies: handelSetCompanies,
    getCompaniesFailed: handleGetCompaniesFailed,
    requestGettingCompanies: handleRequestGetCompanies,
    getCompaniesSuccess: handleGetCompaniesSuccess,
  },
});

export const {
  startGettingCompanies,
  stopGettingCompanies,
  setCompanies,
  getCompaniesFailed,
  requestGettingCompanies,
  getCompaniesSuccess,
} = companiesSlice.actions;

export default companiesSlice.reducer;

export const getCompanies = () => ({
  type: sagaActions.GET_USER_COMPANIES,
});
