const DEV = 'dev';
const DEV1 = 'dev1';
const DEV2 = 'dev2';
const TEST = 'test';
const DEV3 = 'dev3';
const DEV6 = 'dev6';
const DEV7 = 'dev7';
const DEV8 = 'dev8';
const FIX = 'fix';
const DEVEL = 'devel';
const INTEGRATION_TEST = 'integration-test';
const QA = 'qa';

const STAGING1 = 'test-staging';
const STAGING2 = 'test-tyo';

const AP = 'ap';
const EU = 'eu';
const AU = 'au';
const US = 'us';
const UK = 'uk';

type TT_API_ENV_TYPE =
  | 'DEV'
  | 'LOCAL'
  | 'PRODUCTION'
  | 'STAGING'
  | 'CI_TESTING';

const TT_API_ENV =
  process.env.NODE_ENV === 'test'
    ? 'CI_TESTING'
    : (process.env.REACT_APP_TT_API_ENV as TT_API_ENV_TYPE);

const DEV_APIS = {
  [DEV]: process.env.REACT_APP_TT_API_DEV,
  [DEV1]: process.env.REACT_APP_TT_API_DEV1,
  [DEV2]: process.env.REACT_APP_TT_API_DEV2,
  [DEV3]: process.env.REACT_APP_TT_API_DEV3,
  [DEV6]: process.env.REACT_APP_TT_API_DEV6,
  [DEV7]: process.env.REACT_APP_TT_API_DEV7,
  [DEV8]: process.env.REACT_APP_TT_API_DEV8,
  [TEST]: process.env.REACT_APP_TT_API_TEST,
  [FIX]: process.env.REACT_APP_TT_API_FIX,
  [DEVEL]: process.env.REACT_APP_TT_API_DEVEL,
  [INTEGRATION_TEST]: process.env.REACT_APP_TT_API_INTEGRATION_TEST,
  [QA]: process.env.REACT_APP_TT_API_QA,
};

const CI_TESTING_APIS = {
  [DEV]: 'https://dev-app.tt/app3',
  [DEV1]: 'https://dev-app.tt/app1',
  [DEV2]: 'https://dev-app.tt/app2',
};

const LOCAL_APIS = {
  [DEV]: process.env.REACT_APP_TT_API_LOCAL_DEV,
  [DEV1]: process.env.REACT_APP_TT_API_LOCAL_DEV1,
  [DEV2]: process.env.REACT_APP_TT_API_LOCAL_DEV2,
  [TEST]: process.env.REACT_APP_TT_API_LOCAL_TEST,
};

const STAGING_APIS = {
  [STAGING1]: process.env.REACT_APP_TT_API_STAGING,
  [STAGING2]: process.env.REACT_APP_TT_API_STAGING2,
};

const PRODUCTION_APIS = {
  [AP]: process.env.REACT_APP_TT_API_AP,
  [EU]: process.env.REACT_APP_TT_API_EU,
  [AU]: process.env.REACT_APP_TT_API_AU,
  [US]: process.env.REACT_APP_TT_API_US,
  [UK]: process.env.REACT_APP_TT_API_UK,
};

type APIS_BY_ENV_TYPE = {
  DEV: typeof DEV_APIS;
  CI_TESTING: typeof CI_TESTING_APIS;
  LOCAL: typeof LOCAL_APIS;
  STAGING: typeof STAGING_APIS;
  PRODUCTION: typeof PRODUCTION_APIS;
};

const APIS_BY_ENV: APIS_BY_ENV_TYPE = {
  DEV: DEV_APIS,
  CI_TESTING: CI_TESTING_APIS,
  LOCAL: LOCAL_APIS,
  STAGING: STAGING_APIS,
  PRODUCTION: PRODUCTION_APIS,
};

export const TT_APIS = APIS_BY_ENV[TT_API_ENV];
